.vg-custom-select{
    .vg-select__control{
        border-color: $gray-400; border-radius:$border-radius ; min-height: 40px;
        &:hover{border-color: $gray-400;}
        &--is-focused{
            border: 1px solid $blueDark; box-shadow: none;
        }
        &--menu-is-open{
            &:hover{
                border-color: $blueDark;
            }
        }
    }

    .vg-select__indicator-separator{display: none;}

    .vg-select__indicator:not(.vg-select__clear-indicator){
        svg{display: none;}
        &::before{    content: "\f0d7";font-family: 'Font Awesome 5 Pro', sans-serif;font-size: 16px;color: #333333; font-weight: 900;}
    }

    .vg-select__menu{
        border-radius: 3px;border-color: $gray-400;

        .vg-select__menu-list{padding: 0;}

        .vg-select__option{
            &--is-focused, &--is-selected{background:$gray-300 ; color: $gray-900;}
        }

        .selectall{
            display: inline-flex; justify-content: space-between; width: 50%;
            & + .selectall{justify-content: end;}

            &:empty{display: none;}

            &.vg-select__option--is-focused, &.vg-select__option--is-selected{background:transparent;}
            & div{cursor: pointer;
                &:hover{color: $blueDark;}
            }
        }
    }

    &.employee-vg-select{
        .vg-select__menu{ width: 460px;
            .vg-select__menu-list{max-height: 305px!important;}
        }
    }


}