//:root,
 // 
//     --box_shadow: 1px 1px 25px 0 rgba(0, 0, 0, 0.16);
//     --topmenu_box_shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
//     --modal_box_shadow: 0, 0, 0;
//     --bkg_components_overlay_default: var(--modal_box_shadow);
//     --bkg_green_default_rgba: 124, 197, 118;
//     --bkg_yellow_default_rgba: 246, 214, 69;
//     --bkg_neutral_tiertiary-rgba: 231, 231, 231;
//     // dark theme variables: krishna kalaria (08/01/2024)
//     --bkg_neutral_default: white;
//     --border_neutral_default: #E7E7E7;
//     --bkg_alert_default: #d83f34;
//     --text_neutral_inverse: #FFF;
//     --text_neutral_default: #333;
 //   --bkg_neutral_secondary: #F8F8F8;
//     --text_neutral_ondark: #fff;
//     --text_neutral_secondary: #6D6E6F;
//     --text_blue_default: #2a90d6;
//     --bkg_category_bluejay: #478CCA;
//     --bkg_button_green_default: #7CC576;
//     --icon_neutral_default: #333;
//     --border_neutral_strong: #cdcdcd;
//     --bkg_neutral_tiertiary: #e7e7e7;
//     --icon_alert_default: #C5392F;
//     --bkg_theme_blueberry-default: #5162A8;
//     --bkg_category_lowtide: #0EB9A4;
//     --bkg_status_accepted: #96C8EB;
//     --bkg_category_bluejean: #7AA6BA;
//     --bkg_category_tutu: #E6499A;
//     --bkg_neutral_strong: #333;
//     --bkg_warning_default: #FF9F08;
//     --text_secondary: #777777;
//     --bkg_status_show: #A4E8B9;
//     --bkg_category_airhead: #A391C5;
//     --text_neutral_weak: #A2A0A2;
//     --border_blue_default: #2A90D6;
//     --border_button_default: #CDCDCD;
//     --bkg_alert_weak: #fbeceb;
//     --border_components_button_alert_default: #d83f34;
//     --bkg_green_default: #7cc576;
//     --icon_neutral_secondary: #6D6E6F;
//     --bkg_category_coral: #F69681;
//     --bkg_category_cottoncandy: #EFBBD6;
//     --bkg_category_algae: #CBDD4E;
//     --bkg_category_dawn: #C3BFDF;
//     --bkg_blue_weak: #bdddf2;
//     --bkg_flamingoAA_default: #CC4744;
//     --icon_neutral_inverse: #fff;
//     --border_neutral_strong_ondark: #333;
//     --bkg_components_button_green_default: #7cc576;
// }

// html[data-theme="dark"] {
//     --box_shadow: 1px 1px 25px 0 rgba(109, 110, 111, 0.16);
//     --topmenu_box_shadow: 0 2px 8px 0 rgba(109, 110, 111, 0.16);
//     --modal_box_shadow: 109, 110, 111;
//     --bkg_components_overlay_default: var(--modal_box_shadow);
//     --bkg_green_default_rgba: 88, 140, 80;
//     --bkg_yellow_default_rgba: 224, 195, 63;
//     --bkg_neutral_tiertiary-rgba: 51, 51, 51;
//     --recurring-package-img: url(https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/DarkMode/cal-fnew-recurring-new.png);
//     --list-order-change-img: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/atoz-icons-white@2x.png');
//     --dot-img: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com//Images/caret-down-arrow.svg');
//     // dark theme variables - Krishna kalaria (08/01/2024)
//     --bkg_neutral_default: #1e1f21;
//     --border_neutral_default: #4a4a4d;
//     --bkg_alert_default: #992d25;
//     --text_neutral_inverse: #333333;
//     --text_neutral_default: #f8f8f8;
 //    --bkg_neutral_secondary: #252628;
//     --text_neutral_ondark: #f8f8f8;
//     --text_neutral_secondary: #a2a0a2;
//     --text_blue_default: #2683c3;
//     --bkg_category_bluejay: #32638f;
//     --bkg_button_green_default: #588c54;
//     --icon_neutral_default: #f8f8f8;
//     --border_neutral_strong: #6d6e6f;
//     --bkg_neutral_tiertiary: #333;
//     --icon_alert_default: #992d25;
//     --bkg_theme_blueberry-default: #4a5999;
//     --bkg_category_lowtide: #0a8374;
//     --bkg_status_accepted: #6b8ea7;
//     --bkg_category_bluejean: #577684;
//     --bkg_category_tutu: #a3346d;
//     --bkg_neutral_strong: #cdcdcd;
//     --bkg_warning_default: #b57106;
//     --text_secondary: #777777;
//     --bkg_status_show: #74a583;
//     --bkg_category_airhead: #74678c;
//     --text_neutral_weak: #6d6e6f;
//     --border_blue_default: #2a90d6;
//     --border_button_default: #4a4a4d;
//     --bkg_alert_weak: #5B1A16;
//     --border_components_button_alert_default: #C5392F;
//     --bkg_green_default: #588c54;
//     --icon_neutral_secondary: #a2a0a2;
//     --bkg_category_coral: #af6b5c;
//     --bkg_category_cottoncandy: #aa8598;
//     --bkg_category_algae: #909d37;
//     --bkg_category_dawn: #8a889e;
//     --bkg_blue_weak: #70b5e4;
//     --bkg_flamingoaa_default: #913230;
//     --icon_neutral_inverse: #333333;
//     --border_neutral_strong_ondark: #fff;
//     --bkg_components_button_green_default: #588c54;
 //}

@media (prefers-color-scheme: dark) {
    :root {
        --box_shadow: 1px 1px 25px 0 rgba(109, 110, 111, 0.16);
        --topmenu_box_shadow: 0 2px 8px 0 rgba(109, 110, 111, 0.16);
        --modal_box_shadow: 109, 110, 111;
        --bkg_components_overlay_default: var(--modal_box_shadow);
        --bkg_green_default_rgba: 88, 140, 80;
        --bkg_yellow_default_rgba: 224, 195, 63;
        --bkg_neutral_tiertiary-rgba: 51, 51, 51;
        --recurring-package-img: url(https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/DarkMode/cal-fnew-recurring-new.png);
        --list-order-change-img: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/atoz-icons-white@2x.png');
        --dot-img: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com//Images/caret-down-arrow.svg');
        // dark theme variables - Krishna kalaria (08/01/2024)
        --bkg_neutral_default: #1E1F21;
        --border_neutral_default: #4a4a4d;
        --bkg_alert_default: #992D25;
        --text_neutral_inverse: #333333;
        --text_neutral_default: #F8F8F8;
        --bkg_neutral_secondary: #252628;
        --text_neutral_ondark: #f8f8f8;
        --text_neutral_secondary: #a2a0a2;
        --text_blue_default: #2683c3;
        --bkg_category_bluejay: #32638F;
        --bkg_button_green_default: #588c54;
        --icon_neutral_default: #F8F8F8;
        --border_neutral_strong: #6d6e6f;
        --bkg_neutral_tiertiary: #333;
        --icon_alert_default: #992d25;
        --bkg_theme_blueberry-default: #4A5999;
        --bkg_category_lowtide: #0A8374;
        --bkg_status_accepted: #6B8EA7;
        --bkg_category_bluejean: #577684;
        --bkg_category_tutu: #a3346d;
        --bkg_neutral_strong: #cdcdcd;
        --bkg_warning_default: #b57106;
        --text_secondary: #777777;
        --bkg_status_show: #74A583;
        --bkg_category_airhead: #74678C;
        --text_neutral_weak: #6d6e6f;
        --border_blue_default: #2A90D6;
        --border_button_default: #4A4A4D;
        --bkg_alert_weak: #5B1A16;
        --border_components_button_alert_default: #C5392F;
        --bkg_green_default: #588c54;
        --icon_neutral_secondary: #a2a0a2;
        --bkg_category_coral: #af6b5c;
        --bkg_category_cottoncandy: #AA8598;
        --bkg_category_algae: #909d37;
        --bkg_category_dawn: #8A889E;
        --bkg_blue_weak: #70b5e4;
        --bkg_flamingoAA_default: #913230;
        --icon_neutral_inverse: #333333;
        --border_neutral_strong_ondark: #fff;
        --bkg_components_button_green_default: #588c54;
    }
}
.loader-imgNew {
    border-right-color:var(--MenuBackColor) !important;
    border-top-color:var(--MenuBackColor) !important;
    border-bottom-color:var(--MenuBackColor) !important;
    border-left-color:var(--MenuHoverColor) !important;
}



    #vDriveModal .modal-body .card.selected,
    #vDriveModal .modal-body .card.selected .card-body,
    #vDriveModal .modal-body .card,
    #vDriveModal .modal-body .card-body {
        background: transparent !important;
    }


    .vConnectMainWrap {
        // svg path {
        //     fill: var(--icon_neutral_default) !important;
        // }
    }
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.upcoming-appt-wrap .appointment-detail .bottom-icons .serviceIcon {
        .noteRed path {
            fill: #D83F34 !important;
        }
    }
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.upcoming-appt-wrap .appointment-detail .bottom-icons {
        .new-norecoring-icon {
            color: var(--text_neutral_default) !important;
        }
        .dollarSign {
            i {
                color: var(--text_neutral_inverse) !important;
                line-height: 9px;
            }
        }
    }
    .vConnectMainWrap.rightSideOnly .rightSideOnly-header {
        color: var(--text_neutral_default) !important;
        background: var(--bkg_neutral_tiertiary) !important;
        border-color: var(--border_neutral_strong) !important;
    }
    .vConnectMainWrap.rightSideOnly .upgradeBlockWrap,
    .vConnectMainWrap.rightSideOnly .collapsibleChat {
        background: var(--bkg_neutral_default) !important;
        border-color: var(--border_neutral_strong) !important;
    }
    // .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-middle {
    //     background: var(--bkg_neutral_default) !important;
    // }
    .vConnectMainWrap.rightSideOnly.minimize,
    .vConnectMainWrap.rightSideOnly.smallScreen {
        background: transparent !important;
    }
    .vConnectMainWrap.rightSideOnly .collapsibleChat .ck-reset.ck-editor .ck-editor__main {
        background: var(--bkg_neutral_default)!important;
    }
.vConnectMainWrap.rightSideOnly .collapsibleChat .ck-reset.ck-editor.note-active .ck-editor__main {
    border-color: #FF9F08 !important;
    background: #fff5e6 !important;
}

    .vConnectMainWrap .rightside-wrapper .services-list-wrap.pastAppointmentsWrap .appointment-detail .dropdown-toggle i,
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.purchases-wrap .appointment-detail .dropdown-toggle i {
        color: var(--text_neutral_default) !important;
    }

    .chatarea .chatListBlock .chatList-noData {
        &_search {
            color: var(--text_neutral_default) !important;
            background: var(--bkg_neutral_tiertiary) !important;
            border-color: var(--border_neutral_strong) !important;
        }
        // &_title,
        // &_info {
        //     color: var(--text_neutral_secondary)!important;
        // }
    }

    .ck-editor.ck-reset,
    .rightside-wrapper,
    .rightSlide {
        // border-color: var(--border_neutral_strong) !important;
        background: var(--bkg_neutral_default)!important;
    }

    .rightside-wrapper .accordion-item .accordion-header .accordion-button {
        box-shadow: inset 0 -1px 0 var(--border_neutral_strong) !important;
    }

    .fa-arrow-down-left-and-arrow-up-right-to-center {
        color: var(--text_neutral_default) !important;
    }

    .select2-container.select2-container--default.vcEmployeeSelect.ddlSelectCustomer1 .select2-results__options,
    .select2-container.select2-container--default.vcEmployeeSelect .select2-results {
        background: var(--bkg_neutral_default) !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__placeholder,
    .select2-container.select2-container--default.vcEmployeeSelect.ddlSelectCustomer1 .select2-results__option,
    .select2-container.select2-container--default.vcEmployeeSelect .select2-results__options .select2-results__option .labelpadd,
    .select2-container.select2-container--default.vcEmployeeSelect .select2-selectAll--dropdown .selectall.AllCustomer,
    .select2-container.select2-container--default.vcEmployeeSelect .select2-selectAll--dropdown .selectall.AllCustomer:focus,
    .select2-container.select2-container--default.vcEmployeeSelect .select2-selectAll--dropdown .selectall.AllCustomer:hover {
        color: var(--text_neutral_default) !important;
    }

    .select2-container--default .select2-results__option--highlighted {
        background: transparent !important;
    }
    .select2-container--default .select2-results__option:hover {
        background: var(--bkg_neutral_secondary) !important
    }
    .select2-container--default .select2-results__option[aria-selected=true],
    .vConnectMainWrap .chatarea .chatListBlock .chatList-person_dotMenu .dropdown-item:hover {
        background: var(--bkg_neutral_secondary) !important
    }
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.pastAppointmentsWrap .appointment-detail:hover,
    .vConnectMainWrap .rightside-wrapper .appointment-detail:hover {
        background: var(--bkg_neutral_secondary)!important;
    }

    .vConnectMainWrap .rightside-wrapper .services-list-wrap.pastAppointmentsWrap .appointment-detail {
        &:has(.service-wrap.serviceCard) {
            &:hover {
                background: transparent;
                
            }
        }
    }
.vConnectMainWrap.rightSideOnly .rightSideOnly-navBlock_option {
    border-color: var(--border_neutral_default) !important;

    &.active {
        color: var(--text_neutral_default) !important;
        border-color: var(--text_neutral_default) !important;
    }
}
    /*.vConnectMainWrap .chatarea .chatListBlock-searchBlock .serachGrooup .searchFieldIcon:hover {
        color: #fff;
    }*/
    .vConnectMainWrap .extendButton.minimize {
        &:hover {
            background: var(--bkg_neutral_tiertiary)!important;
        }
    }
.vConnectMainWrap .rightside-wrapper .services-list-wrap.upcoming-appt-wrap .appointment-detail .bottom-icons span.name-icon {
    background-color: var(--text_neutral_default) !important;
    color: var(--text_neutral_inverse) !important;
}

    // .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.messagesEditWrap .messagesBox-block .messages .typeMsgText {
    //     background: var(--bkg_neutral_default) !important;
    //     color: var(--text_neutral_default) !important;
    // }
    .vConnectMainWrap.rightSideOnly.smallScreen .messagesArea {
        background: var(--bkg_neutral_tiertiary)!important;
    }

    .vConnectMainWrap .chatarea .messagesArea-middle .chat-deleted {
        background: var(--bkg_neutral_tiertiary)!important;
        border-radius: 5px;
        padding: 10px;
        &Wrap {
        }
        &_undo {
            color: #7b80eb;
        }
        
    }


    .vConnectMainWrap .chatarea .chatListBlock-searchBlock .serachGrooup input.searchField,
    .vgn-modal-alert .modal-content,
    .vgn-modal-alert .modal-footer {
        background: var(--bkg_neutral_default) !important;
        border-color: var(--border_neutral_default) !important;
        color: var(--text_neutral_default) !important;
    }

    .ck.ck-editor__main>.ck-editor__editable,
    .ck.ck-toolbar {
        // background: var(--bkg_neutral_default) !important;
        // color: var(--text_neutral_default) !important;
    }

    .select2-container.select2-container--default.vcEmployeeSelect .select2-results__option {
        color: var(--text_neutral_default) !important;
    }
    .select2-container .select2-selection--multiple:after,
    .select2-container .select2-selection--single:after {
        color: var(--text_neutral_default) !important;
    }

    // .vConnectMainWrap .EmojiPickerReact,
    .vConnectMainWrap .gifPluginBlock,
    .vConnectMainWrap .EmojiPickerReact .epr-emoji-category-label {
        background: var(--bkg_neutral_tiertiary, #e7e7e7)!important;
        color: var(--text_neutral_default, #333) !important;
    }
    .epr_q53mwh,
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: var(--text_neutral_default) !important;
    }

    .select-employee-wrap .select2-selection--multiple .select2-selection__rendered {
        background-color: var(--bkg_neutral_default) !important;
        border: 1px solid var(--border_neutral_strong) !important;
    }
    .add-member-dialogue .modal-footer {
        background-color: var(--bkg_neutral_secondary) !important;
        border-top: 1px var(--border_neutral_default) solid !important;
    }
    .add-member-dialogue .modal-footer .btn-secondary {
        background-color: var(--bkg_components_button_neutral_default) !important;
        border: 1px var(--border_neutral_strong) solid !important;
        color: var(--text_neutral_default) !important;
    }
    .rightside-wrapper .member-list-wrapper .chatList-person_dotMenu .dropdown-item:hover {
        background: var(--bkg_neutral_secondary)!important;
    }
    .dropdown .dropdown-menu {
        background: var(--bkg_neutral_default) !important;
        border-color: var(--border_neutral_default) !important;
        color: var(--text_neutral_default) !important;
        .dropdown-item {
            color: var(--text_neutral_default) !important;
            &:hover {
                background: var(--bkg_neutral_secondary) !important;
            }
        }
    }

    .image-popup-wrap .modal-header .download-close {
        color: var(--icon_neutral_default, #333)!important;
    }

    .modal-header .btn-close {
        color: var(--icon_neutral_secondary, #6d6e6f)!important;
        &::after {
            color: var(--icon_neutral_secondary, #6d6e6f)!important;
        }
        &:hover {
            color: var(--icon_neutral_default ,#333)!important;
            &::after {
                color: var(--icon_neutral_default, #333)!important;
            }
        }
    }
    .modal-footer .btn.btn-primary {
        background-color: var(--bkg_components_button_green_default) !important;
        border-color: var(--border_green_default) !important;
        color: var(--text_neutral_ondark) !important;
        &:hover {
            background-color: var(--bkg_components_button_green_hover) !important;
            border-color: var(--bkg_components_button_green_hover) !important;
            color: var(--text_neutral_ondark) !important;
        }
    }

    .modal-footer .btn.btn-secondary {
        background-color: var(--bkg_components_button_neutral_default) !important;
        border: 1px var(--border_neutral_strong) solid !important;
        color: var(--text_neutral_default) !important;
        &:hover {
            background-color: var(--bkg_components_button_neutral_hover) !important;
            border: 1px var(--border_neutral_strong) solid !important;
            color: var(--text_neutral_default) !important;
        }
    }

    .modal-content {
        border: 1px solid var(--border_neutral_default)!important;
    }

    .modal-header {
        color: var(--text_neutral_default) !important;
        .modal-title,
        .modal-title span {
            color: var(--text_neutral_default) !important;
        }
    }
    .modal-body p,
    .add-member-dialogue .chat-history-box .check-label,
    .modal-body > * {
        color: var(--text_neutral_default) !important;
    }

    .modal-title {
        color: var(--text_neutral_default) !important;
    }

    // .vConnectMainWrap {
        // .avatar[data-avatar="1"] {
        //     .review-profile-inner-text {
        //     }
        // }
        .avatar-group-img-wrap div .review-profile-inner-text,
        .avatar-group-img-wrap-text {
            background-color: var(--bkg_neutral_tiertiary) !important;
            color: var(--text_neutral_default) !important;
            border-color: var(--border_neutral_strong) !important;
        }
    // }


.vConnectMainWrap {
    background: var(--bkg_neutral_secondary) !important;

    .saveControl {
        span {
            color: var(--text_neutral_default) !important;
        }

        &-cancel {
            &::after {
                color: var(--text_neutral_default) !important;
            }
        }
    }



    .vCbadge {
        background: var(--bkg_alert_default) !important;
        // color: var(--bkg_neutral_default) !important;
    }

    .leftside {
        background: var(--bkg_neutral_default) !important;
        border-color: var(--border_neutral_default) !important;

        svg path {
            fill: var(--text_neutral_secondary) !important;
        }

        .upgradeBlock {
            background: var(--bkg_category_bluejay) !important;
            color: var(--text_neutral_ondark) !important;

            .textArea {
                color: var(--text_neutral_ondark) !important;

                &-title,
                &-info {
                    color: var(--text_neutral_ondark) !important;
                }
            }

            &-btn {
                background: var(--bkg_neutral_default) !important;
                color: var(--text_neutral_default) !important;
            }
        }

        &-toggle {
            color: var(--text_neutral_secondary) !important;
        }

        &-menu {
            &_options {
                color: var(--text_neutral_default) !important;

                &:hover,
                &.active {
                    color: #333 !important;
                }
            }
        }

    }
    .textCounArea {
        border-color: var(--border_neutral_default) !important;

        &-sent,
        &-contact {
            color: var(--text_neutral_default) !important;
        }

        &-limit {
            color: var(--text_blue_default) !important;
        }

        .progress {
            background: var(--bkg_neutral_tiertiary) !important;

            &-bar {
                background: var(--bkg_green_default) !important;
            }
        }
    }

    .welcomeScreen {
        background: var(--bkg_neutral_secondary) !important;

        &-block {
            color: var(--text_neutral_default) !important;
        }
    }

    .typeChatWrap {
        &-block {
            background: var(--bkg_neutral_default) !important;
            border-color: var(--border_neutral_strong) !important;

            &_options {
                &-left {
                    .optionIcon {
                        color: var(--text_neutral_weak) !important;

                        svg {
                            path {
                                fill: var(--text_neutral_weak) !important;
                            }
                        }
                    }
                }

                &-right {
                    .btn-send {
                        color: var(--text_neutral_ondark) !important;
                        background: var(--bkg_button_green_default) !important;
                    }
                }
            }
        }

        textarea.textBlock {
            color: var(--text_neutral_default) !important;

            &::placeholder {
                color: var(--text_neutral_weak) !important;
            }
        }
    }

    .chatarea {
        .messagesArea {
            &-header {
                background: var(--bkg_neutral_default) !important;
                border-color: var(--border_neutral_default) !important;

                &_btnArea {
                    .btn-addCtm {
                        border-color: var(--border_neutral_strong) !important;
                        background: var(--bkg_neutral_default) !important;
                        color: var(--text_neutral_default) !important;
                    }
                }

                &_name {
                    color: var(--text_neutral_default) !important;
                }
            }

            &-middle {

                .inChatmsg {
                    color: var(--text_neutral_weak) !important;
                }

                .messagesBox {
                    &-block {
                        .name {
                            color: var(--text_neutral_default) !important;
                        }

                        .messages {
                            // background: var(--bkg_neutral_tiertiary) !important;
                            // color: var(--text_neutral_default) !important;
                        }

                        .time {
                            color: var(--text_neutral_secondary) !important;

                            .chechIcon::before {
                                // color: var(--text_green_Strong) !important;
                                /*color: #588C54;*/
                            }
                        }
                    }

                    &.sent,
                    &.recived {
                        .messages {
                            &.message-note-block {
                                border-color: #FF9F08 !important;
                                // background: #FFE1B2 !important;
                                color: #333 !important;
                            }
                        }
                    }

                    &.sent {
                        .messages {
                            // background: #5162A8 !important; // theme default
                            // background: var(--bkg_theme_blueberry-default) !important;
                            color: var(--text_neutral_ondark) !important;
                        }
                    }

                    .vcCard {
                        border-color: #CDCDCD !important;
                        background: var(--bkg_neutral_default) !important;
                        color: var(--text_neutral_default);

                        &.messages {
                            border-color: #CDCDCD !important;
                            background: var(--bkg_neutral_default) !important;
                            color: var(--text_neutral_default) !important;
                        }


                        &-title {
                            &_icon {
                                background: var(--bkg_category_dawn) !important;
                            }
                        }

                        &-button {
                            border-color: var(--border_components_button_default) !important;
                            background: var(--bkg-components_button_neutral_hover) !important;
                            color: var(--text_neutral_default) !important;
                        }
                    }
                }
            }
        }

        .upgradeBlock {
            background: var(--bkg_blue_weak) !important;

            .textArea {

                &-title,
                &-info {
                    color: var(--text_neutral_default) !important;
                    svg,
                    svg path {
                        fill: var(--text_neutral_default) !important;
                    }
                }
            }

            &-btn {
                border: 1px solid #CDCDCD;
                background: var(--bkg_neutral_default);
                color: var(--text_neutral_default);
            }
        }

        &.rightSideOnly {
            border-color: var(--border_neutral_default) !important;


            .rightSideOnly-header {
                background: var(--bkg_neutral_default);
                box-shadow: 0px -1px 0px 0px #E7E7E7 inset;

                &_title {
                    color: var(--text_neutral_default) !important;
                }
            }

            &.chatActive {
                .messagesArea {
                    &-header {
                        &_name {
                            color: var(--text_neutral_default);
                        }
                    }

                    &-middle {
                        background: var(--bkg_neutral_secondary);
                    }
                }
            }

            &.smallScreen {
                .rightSideOnly-header {
                    background: var(--bkg_theme_blueberry_default);

                    &_title {
                        color: var(--text_neutral_inverse);
                    }
                }
            }
        }
    }

    .chatList {
        &-header {
            border-color: var(--border_neutral_strong) !important;

            &_menu {
                color: var(--text_neutral_secondary) !important;

                &.active {
                    border-color: var(--border_neutral_strong_ondark) !important;
                    color: var(--text_neutral_default) !important;
                }
            }
        }

        &Block {
            background: var(--bkg_neutral_default) !important;
            border-color: var(--border_neutral_default) !important;
        }


        &-group {
            &_header {
                background: var(--bkg_neutral_secondary) !important;
                color: var(--text_neutral_secondary) !important;
            }
        }

        &-person {
            border-color: var(--border_neutral_strong) !important;
            color: var(--text_neutral_default) !important;

            &_dotMenu {
                color: var(--icon_neutral_default) !important;

                .dropdown {
                    &-menu {
                        border-color: var(--border_neutral_default) !important;
                        background: var(--bkg_neutral_default) !important;
                    }

                    &-item {
                        padding: 12px;
                        color: var(--text_neutral_default) !important;

                        &:hover {
                            background: #fff;
                        }
                    }
                }
            }
            
            &:hover {
                background: var(--bkg_neutral_secondary) !important;
            }
            &.active {
                background: var(--bkg_neutral_tiertiary) !important;
            }


            .status {

                &.unread {
                    background-color: var(--icon_alert_default) !important;
                }
            }

            &_name,
            &_time,
            &_number {
                color: var(--text_neutral_default) !important;
            }

            &_messages {
                color: var(--text_neutral_secondary) !important;
            }
        }
    }

    .ddlSelectCustomer1.select2-container.select2-container--default.vcEmployeeSelect {
        // border: 1px solid var(--border_neutral_default);
        background: var(--bkg_neutral_default);
    }

    .employeeSelectWrap {
        .select2-container--default {
            .select2-selection {

                &--multiple,
                &--single {
                    // border: 1px solid var(--border_neutral_default);
                    background: var(--bkg_neutral_default);

                    .select2-selection__rendered {
                        .select2-search--inline {
                            .select2-search__field {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    .rightSideOnly-header_title {
        color: var(--text_neutral_default) !important;
    }

    .extendButton {
        .icon {
            i {
                color: var(--icon_neutral_default) !important;
            }
        }
    }
    // START: CK editer
    .cke_inner {
        border: 1px solid var(--border_neutral_strong) !important;
        background: var(--bkg_neutral_default) !important;

        .cke_wysiwyg_frame {
            background-color: var(--bkg_neutral_default) !important;
        }

        .cke_toolgroup {
            .cke_button_icon {
                color: var(--text_neutral_weak) !important;
            }
        }

        .cke_button.cke_button_Send,
        .cke_button.cke_button_Send:hover,
        .cke_button.cke_button_Send:focus,
        .cke_button.cke_button_Send:active {
            background: var(--bkg_components_button_green_default) !important;
            color: var(--text_neutral_ondark) !important;
        }
    }



    .vcDrop {
        &-wrap {
            background: var(--bkg_neutral_default) !important;

            &.linkWrap {
                background: var(--bkg_neutral_default) !important;
            }
        }

        &-group {
            border: 1px solid var(--border_neutral_strong) !important;

            &-item {
                color: var(--text_neutral_default) !important;

                &:hover {
                    background: var(--bkg_neutral_tiertiary) !important;
                }
            }
        }
    }
    // END: CK editer

    .smallChatBox {
        // &Wrap {
        //     background: var(--bkg_flamingoAA_default) !important;
        // }

        &-header {
            &_title {
                color: var(--text_neutral_ondark) !important;
            }

            &_actions {
                a {
                    color: var(--text_neutral_ondark) !important;
                }
            }
        }

        &-middle {
            background: var(--bkg_neutral_secondary) !important;

            &_nameArea {
                color: var(--text_neutral_default) !important;
            }

            &_messagesWrap {
                background: var(--bkg_neutral_tiertiary) !important;
            }

            .messagesBox {

                .messages {
                    background: var(--bkg_flamingoAA_default) !important;
                    color: var(--text_neutral_ondark) !important;
                }

                .time {
                    color: var(--text_neutral_secondary) !important;

                    .chechIcon::before {
                        color: var(--text_green_Strong) !important;
                    }
                }

                .name {
                    color: var(--text_neutral_default) !important;
                }

                &.recived {

                    .messages {
                        background: var(--bkg_neutral_tiertiary) !important;
                        color: var(--text_neutral_default) !important;
                    }
                }
            }
        }

        &-footer {
            background: var(--bkg_neutral_default) !important;
            border-color: var(--border_neutral_default) !important;



            &_fieldWrap {

                .inputField {
                    border-color: var(--border_neutral_default) !important;
                    color: var(--text_neutral_weak) !important;
                }
            }

            .note-icon {
                i {
                    color: #FF9F08 !important;
                }
            }

            i {
                color: var(--bkg_theme_blueberry_default) !important;
            }

            svg path {
                fill: var(--bkg_theme_blueberry_default) !important;
            }
        }
    }

    .smallChatBoxWrap {
        .smallChatBox-footer {
            i {
                color: var(--text_neutral_weak) !important;
            }

            svg path {
                fill: var(--text_neutral_weak) !important;
            }
        }
    }

    .avatar {
        // background: #2A90D6 !important;
        // border-color: var(--border_neutral_strong) !important;

        &.group {
            background: var(--bkg_blue_weak) !important;
            border-color: var(--bkg_status_accepted) !important;
        }

        &-group-img-wrap {
            border-color: var(--bkg_status_accepted) !important;
            background: var(--bkg_blue_weak) !important;

            &-text {
                color: var(--text_neutral_default) !important;
            }
        }
    }

    .rightside {
        .businessLocation {
            &Block {
                .locationList {
                    .location {
                        color: var(--text_neutral_default) !important;
                    }

                    .locationToogle {
                        color: var(--text_neutral_secondary) !important;
                    }
                }
            }
        }

        .rightside-wrapper {
            background-color: var(--bkg_neutral_default) !important;
            border-color: var(--border_neutral_default) !important;

            .services-list-wrap {
                &.upcoming-appt-wrap {
                    .appointment-detail {
                        .service-wrap {
                            // background-color: var(--bkg_category_lowtide) !important;
                            // border-color: var(--bkg_status_accepted) !important;
                            // &::before,
                            // &::after {
                            //     content: '';
                            //     background-color: var(--bkg_category_bluejean) !important;
                            // }

                            &.class-wrap {
                                background-color: var(--bkg_category_tutu) !important;
                                color: var(--bkg_neutral_default) !important;

                                .bottom-icons {
                                    color: var(--text_neutral_default) !important;

                                    .count {
                                        background-color: var(--bkg_neutral_default) !important;

                                        span {
                                            border-color: var(--border_neutral_strong) !important;
                                            color: var(--text_neutral_default) !important;
                                        }
                                    }
                                }
                            }
                        }

                        .bottom-icons {
                            &::after {
                                content: '';
                                background-color: var(--icon_neutral_default) !important;
                            }

                            span {
                                i {
                                    color: var(--text_neutral_default) !important;
                                }

                                &:last-child {
                                    // background-color: var(--text_neutral_default) !important;
                                    // color: var(--bkg_neutral_default) !important;
                                }
                            }
                        }
                    }
                }
            }

            .profile-wrapper {
                h3 a {
                    color: var(--text_neutral_default) !important;
                }
                h3 {
                    color: var(--text_neutral_default) !important;
                }

                .membership-name {

                    svg {
                        path {
                            fill: var(--icon_neutral_inverse) !important;
                        }
                    }
                }
            }

            .contact-wrap {
                a {
                    color: var(--text_neutral_default) !important;
                }
            }

            .block-status {
                .active-status {
                    color: var(--text_neutral_ondark) !important;

                    &.status {
                        &-red {
                            background-color: var(--bkg_alert_default) !important;
                        }

                        &-yellow {
                            background-color: var(--bkg_warning_default) !important;
                        }

                        &-green {
                            background-color: var(--bkg_green_default) !important;
                        }

                        &-gray {
                            // background-color: var(--text_secondary);
                            background-color: #777777 !important;
                        }
                    }
                }
            }

            .appointment-detail {
                .service-icon {
                    &.icon {

                        &-green {
                            background-color: var(--bkg_status_show) !important;
                        }

                        &-blue {
                            background-color: var(--bkg_status_accepted) !important;
                        }

                        &-purple {
                            background-color: var(--bkg_category_airhead) !important;
                        }

                        &-orange {
                            background-color: var(--bkg_category_coral) !important;
                        }

                        &-light-purple {
                            background-color: var(--bkg_category_dawn) !important;
                        }

                        &-pink {
                            background-color: var(--bkg_category_cottoncandy) !important;
                        }

                        &-yellow {
                            background-color: var(--bkg_category_algae) !important;
                        }
                    }

                    i {
                        color: var(--icon_neutral_default) !important;
                    }
                }

                .service-wrap {
                    // h3,
                    // p
                    .date-wrap,
                    .service-title {
                        color: var(--text_neutral_default) !important;
                    }
                }

                .date {
                    color: var(--text_neutral_default) !important;
                }
            }

            .note {
                background-color: var(--bkg_alert_weak) !important;
                border-color: #D83F34;

                p {
                    color: var(--text_neutral_default) !important;
                }
            }

            .member-list-wrapper {
                #groupNameInput {
                    border-color: var(--border_neutral_strong) !important;
                    color: var(--text_neutral_default) !important;
                    background: #ffffff;
                }

                .profile-image {
                    .avatar-group {
                        border-color: var(--bkg_status_accepted) !important;
                    }
                }
            }

            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        color: var(--text_neutral_default) !important;
                    }
                }
            }

            .carousel-control {

                &-prev-icon,
                &-next-icon {
                    &::before {
                        color: var(--icon_neutral_secondary) !important;
                    }
                }
            }
        }
    }
}

    .add-member-dialogue {
        .modal-content {
            background-color: var(--bkg_neutral_default, #fff) !important;
        }
    }

    .image-popup-wrap {
        .modal-content {
            background-color: var(--bkg_neutral_default, #fff) !important;

            .image-gallery {
                &-icon {
                    color: var(--text_neutral_secondary) !important;
                }
            }
        }

        .modal-header {
            background-color: var(--bkg_neutral_secondary, #f8f8f8) !important;

            .modal-title {
                color: var(--text_neutral_default, #333) !important;
            }

            .btn-close {
                &::before {
                    color: var(--icon_neutral_default) !important;
                }
            }
        }
    }

    // Vagaro Drive modal start
    #vDriveModal {

        .modal {
            &-header {
                background: var(--bkg_neutral_default) !important;
            }

            &-title {
                color: var(--text_neutral_default) !important;
            }

            &-body {
                background: var(--bkg_neutral_default) !important;

                .tabTitle {
                    color: var(--text_neutral_default) !important;
                }

                .nav-pills {

                    .nav-link {
                        color: var(--text_neutral_default) !important;
                    }

                    .nav-link.active,
                    .show>.nav-link {
                        background: var(--bkg_neutral_tiertiary) !important;
                    }
                }

                .card {

                    &.selected {
                        border-color: var(--border_blue_default) !important;

                        .card-body {
                            // background: var(--bkg-components-button-bkg-components-ghost-hover, #EAF4FB);
                            background: #EAF4FB !important;
                        }
                    }

                    .selectedCount {
                        border-color: var(--border_blue_default) !important;
                        background: #FFF !important;
                        color: #000 !important;
                    }

                    &-imgWrap {
                        background: #eee;
                    }

                    // &-body {
                    //     background: #fff;
                    // }

                    &-title {
                        color: var(--text_neutral_default) !important;
                    }

                    &-text {
                        color: var(--text_neutral_secondary) !important;
                    }
                }
            }

            &-footer {
                background-color: var(--bkg_neutral_secondary) !important;

                .btn-secondary,
                .btn-secondary:hover,
                .btn-secondary:focus {
                    border-color: var(--border_button_default) !important;
                    background: var(--bkg_neutral_default) !important;
                    color: var(--text_neutral_default) !important;
                }

                .btn-primary,
                .btn-primary:hover,
                .btn-primary:focus {
                    background: var(--bkg_green_default) !important;
                    border-color: var(--bkg_green_default) !important;
                    color: var(--text_neutral_ondark) !important;
                }
            }
        }
    }

    .select2-results-count {
        background-color: var(--bkg_neutral_secondary) !important;
        color: var(--text_neutral_default) !important;
    }
    .select2-search__field {
        background: var(--bkg_neutral_default) !important;
        // border: 1px solid var(--border_neutral_default) !important;
        color: var(--text_neutral_default) !important;
        &::placeholder {
            color: var(--text_neutral_default) !important;
        }
    }
    .select2-selection--multiple {
        .select2-search__field {
            border: none !important;
        }
    }
    
    .select2-container.select2-container--default .select2-dropdown .select2-results .select2-results__options .select2-results__option[aria-selected=false] .multicheck-checkbox {
        background-color: var(--bkg_neutral_default) !important;
        border: 1px solid var(--border_neutral_strong) !important;
    }
    .select2-results,
    .ddlSelectCustomer1.select2-container.select2-container--default.vcEmployeeSelect .select2-search--dropdown {
        border: 1px solid var(--border_neutral_default) !important;
    }

    .no-data {
        color: var(--text_neutral_secondary) !important;
    }
    .vConnectMainWrap .chatarea .messagesArea-middle .inChatmsg.personBlockText {
        color: var(--text_neutral_weak);

    }
.select-employee-wrap .select2-selection--multiple .select2-selection__choice {
    border: 1px solid var(--border_neutral_default);
    background: var(--bkg_neutral_secondary);
    color: var(--text_neutral_default);
    // border: 1px solid var(--border_neutral_default);
    // background: var(--bkg-neutral-secondary);
    // color: var(--text_neutral_default);
}

.select-employee-wrap .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
    // color: var(--text_neutral_default);
    color: var(--icon_neutral_default);
}

.dropdown {
    color: var(--icon_neutral_default);

    .dropdown {
        &-menu {
            background: var(--bkg_neutral_default);
        }

        &-item {
            color: var(--text_neutral_default);

            &:hover {
                background: var(--bkg_neutral_secondary);
                color: var(--text_neutral_default);
            }

            &.active {
                background: var(--bkg_neutral_tiertiary);
                color: var(--text_neutral_default);
            }
        }
    }
}

/*- Start: Siddhi changes darkmode theme -*/
.dropdown-item:focus, .dropdown-item:hover { background-color:var(--bkg_neutral_secondary)!important}
.vConnectMainWrap .chatarea .upgradeBlock {
    background: var(--bkg_blue_weak) !important;
}

.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox-block .messages {
    background: var(--bkg_neutral_tiertiary);
    color: var(--text_neutral_default);
}

.vConnectMainWrap .leftside-menu_options.active, .vConnectMainWrap .leftside-menu_options:hover {
    background: var(--bkg_neutral_tiertiary);
    color: var(--text_neutral_default) !important;
}

.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages .editControlWrap {
    background: var(--bkg_neutral_default);
}

.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages .editControl i {
    color: var(--text_neutral_default);
}

.vConnectMainWrap .EmojiPickerReact .epr-header .epr-search-container input {
    background: var(--bkg_neutral_tiertiary);
    color: var(--text_neutral_weak);
}

.vConnectMainWrap .gifPluginBlock .reactGiphySearchbox-searchForm-form .reactGiphySearchbox-searchForm-input {
    background: var(--bkg_neutral_tiertiary);
    color: var(--text_neutral_weak);
}

.vConnectMainWrap .employeeSelectWrap .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: var(--bkg_neutral_secondary);
    border: 1px solid var(--border_neutral_default);
}

.vConnectMainWrap .employeeSelectWrap .select2-container--default .select2-selection--multiple .select2-selection__rendered span {
    color: var(--text_neutral_default) !important
}

.vConnectMainWrap .vcDrop-group {
    border: transparent !important;
}
.vConnectMainWrap .chatarea .messagesArea-middle::-webkit-scrollbar-thumb {
    background: var(--bkg_components_selector_disabled);
}
.vConnectMainWrap .EmojiPickerReact .epr-body::-webkit-scrollbar-thumb {
    background: var(--bkg_components_selector_disabled);
}

.vConnectMainWrap .vcDrop-wrap.linkWrap .vcDrop-group::-webkit-scrollbar-thumb,
.rightside .bottom-content-wrap::-webkit-scrollbar-thumb,
.vConnectMainWrap .chatarea .chatListBlock .chatList-groupBlock::-webkit-scrollbar-thumb,
.vConnectMainWrap .gifPluginBlock .reactGiphySearchbox-listWrapper::-webkit-scrollbar-thumb,
.select2-container.select2-container--default.select2-container--open.vcEmployeeSelect.GroupddEmployee .select2-results__options::-webkit-scrollbar-thumb,
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.messagesEditWrap .ck-editor.ck-reset .ck-editor__main .ck-editor__editable::-webkit-scrollbar-thumb,
.ck-editor.ck-reset .ck-editor__main .ck-editor__editable::-webkit-scrollbar-thumb,
.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-thumb,
.select2-container.select2-container--default.vcEmployeeSelect .select2-results::-webkit-scrollbar-thumb,
.select2-container.select2-container--default.vcEmployeeSelect .select2-results .select2-results__options::-webkit-scrollbar-thumb {
    background: var(--bkg_components_selector_disabled);
}

.vConnectMainWrap .chatarea .messagesArea-middle::-webkit-scrollbar-track {
    background: var(--bkg_neutral_tiertiary);
}
.vConnectMainWrap .EmojiPickerReact .epr-body::-webkit-scrollbar-track {
    background: var(--bkg_neutral_tiertiary);
}
.vConnectMainWrap .vcDrop-wrap.linkWrap .vcDrop-group::-webkit-scrollbar-track,
.rightside .bottom-content-wrap::-webkit-scrollbar-track,
.vConnectMainWrap .chatarea .chatListBlock .chatList-groupBlock::-webkit-scrollbar-track,
.vConnectMainWrap .gifPluginBlock .reactGiphySearchbox-listWrapper::-webkit-scrollbar-track,
.select2-container.select2-container--default.select2-container--open.vcEmployeeSelect.GroupddEmployee .select2-results__options::-webkit-scrollbar-track,
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.messagesEditWrap .ck-editor.ck-reset .ck-editor__main .ck-editor__editable::-webkit-scrollbar-track,
.ck-editor.ck-reset .ck-editor__main .ck-editor__editable::-webkit-scrollbar-track,
.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-track,
.select2-container.select2-container--default.vcEmployeeSelect .select2-results::-webkit-scrollbar-track,
.select2-container.select2-container--default.vcEmployeeSelect .select2-results .select2-results__options::-webkit-scrollbar-track {
    background: var(--bkg_neutral_tiertiary);
}

.vConnectMainWrap .EmojiPickerReact .epr-header .epr-search-container input::placeholder {
    color: var(--text_neutral_weak);
}

.vConnectMainWrap .EmojiPickerReact .epr-header .epr-search-container input {
    border: transparent;
}
.vConnectMainWrap .employeeSelectWrap .select2-container--default .select2-selection--multiple .select2-selection__choice__remove > span{
    opacity: 1;
}
#vDriveModal .modal-content {
    border: none !important;
}
.vConnectMainWrap .rightside-wrapper .contact-wrap-emailText {
    color: var(--text_neutral_default) !important;
}
.vConnectMainWrap .rightside .rightside-wrapper .contact-wrap a {
    text-decoration: none;
    color: var(--text_neutral_default) !important;
}
.ck-editor.ck-reset.note-active {
    /* background: var(--bkg_warning_weak) !important;
    border: var(--border_warning_default) !important;*/
}
.appointment-detail .dropdown .dropdown-menu {
    border: 1px solid var(--border_neutral_default);
}
.vConnectMainWrap.rightSideOnly .rightSideOnly-header{
    box-shadow: none;
}
.vConnectMainWrap .rightside-wrapper .services-list-wrap.upcoming-appt-wrap .appointment-detail .bottom-icons span.name-icon i.fas{
color: var(--text_neutral_inverse)!important;
}
.select-employee-wrap .avatar-group-img-wrap {
    background-color: var(--bkg_neutral_tiertiary) !important;
    border-color: var(--border_neutral_strong) !important;
    color: var(--text_neutral_default) !important;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .ck.ck-reset.ck-editor {
    background: var(--bkg_neutral_default) !important;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .ck.ck-reset.ck-editor .ck.ck-toolbar.ck-toolbar_grouping {
    background: var(--bkg_neutral_default) !important;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .collapsibleChat .ck-reset.ck-editor.note-active .ck-editor__main {
    background: #fff5e6 !important;
}
.vConnectMainWrap .vcDrop-dropBlock.active {
    background: var(--bkg_neutral_default) !important;
}
.vConnectMainWrap .vcDrop-wrap.linkWrap .vcDrop-group-item {
    background: var(--bkg_neutral_default) !important;
}
.vcDrop-dropBlock-childWrap {
    background: var(--bkg_neutral_default) !important;
}
.vConnectMainWrap .rightside .rightside-wrapper .appointment-detail .service-wrap .addOn, .vConnectMainWrap .rightside .rightside-wrapper .appointment-detail .service-wrap p{
    color: var(--text_neutral_default) !important;
}
.vConnectMainWrap .vcDrop-wrap.linkWrap .vcDrop-group-item:hover {
    background: var(--bkg_neutral_tiertiary) !important;
}
.epr-header.epr_iogimd .epr_-7mhz5e:hover {
    background: none;
}
.epr-header.epr_iogimd .epr_-7mhz5e .epr_154h9l:hover {
    background-position-y: 0px;
}
.vcMobile .mobileListmenu .dropdown-toggle {
    color: var(--MenuBackColor) !important;
}
.vConnectMainWrap .rightside-wrapper .member-list-wrapper .chatList-person_dotMenu .dropdown-toggle i {
    color: var(--icon_neutral_default) !important;
}

.select2-container.select2-container--default.vcEmployeeSelect .select2-search--dropdown::after {     color: var(--text_neutral_default) !important;}
.vConnectMainWrap .employeeSelectWrap .select2-container--default .select2-selection--single { border-color:var(--border_neutral_default)!important}
.mobileListmenuWrap .ck-editor.ck-reset { border-color:var(--border_neutral_default)!important}
.select2-container--default.vcEmployeeSelect .select2-search--dropdown .select2-search__field { border:solid 1px var(--border_neutral_default)!important}


@media (max-width: 767px) {
    // .vcMobile .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages {
    //     background: var(--ButtonBackColor) !important;
    // }
    .vcMobile .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages.message-note-block {
        background: #fff5e6 !important;
    }
    .vcMobile .mobileListmenu .dropdown-toggle {
        // color: var(--ButtonBackColor) !important;
        background: linear-gradient(180deg, #09699F 0%, #533B88 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
.vConnectMainWrap .avatar-group-img-wrap {
    background: var(--bkg_neutral_tiertiary) !important;
    border-color: var(--border_neutral_strong) !important;
}
/*- end: Siddhi changes darkmode theme -*/
/*- Start: color theme -*/
.vConnectMainWrap {
    // .leftside-menu_options {
    //     &.active {
    //         background: var(--MenuBackColor)!important;
    //     }
    //     &:hover {
    //         background: var(--MenuHoverColor);
    //         color: #fff;
    //     }
    // }
    .chatarea .messagesArea-middle .messagesBox.sent .messages {
        background: var(--ButtonBackColor) !important;
        color: var(--MenuFontColor) !important;
    }

    &.rightSideOnly.smallScreen .rightSideOnly-header {
        background: var(--MenuBackColor) !important;
        color: var(--MenuFontColor) !important;
    }

    .chatarea .messagesArea-middle .messagesBox.sent {
        .messages a {
            color: var(--MenuFontColor) !important;
    
            &.vcDrop-group-item,
            &.editControl {
                color: var(--text_neutral_default) !important;
            }
        }
        &.messagesEditWrap {
            .messages a {
                color: var(--text_neutral_default) !important;
            }

        }
    }

    &.rightSideOnly.smallScreen .rightSideOnly-header_iconWrap i.fa-solid,
    &.rightSideOnly.smallScreen .rightSideOnly-header_title {
        color: var(--MenuFontColor) !important;
    }
}

.vConnectMainWrap.rightSideOnly.smallScreen .rightSideOnly-header {
    background: var(--MenuBackColor)!important;
}
.vConnectMainWrap .vcDrop-group{
    border: none;
}
.vConnectMainWrap .vcDrop-group-item:hover {
    border: var(--border_neutral_default);  
}
.rightSlide {
    border: 1px solid var(--border_neutral_default);
}
.vcMobile .vConnectMainWrap.rightSideOnly .collapsibleChat, .vConnectMainWrap.rightSideOnly .upgradeBlockWrap {
    background: var(--bkg_neutral_secondary) !important;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred p, .ck.ck-editor__main, .ck-editor.ck-reset .ck-editor__main .ck-editor__editable {
    color: var(--text_neutral_default) !important;
}
.note-active {
    .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline p {
        color: #ff9f08!important;
    }
}
.vConnectMainWrap .chatarea .stencilBlock-messagesArea .messagesArea-middle .messagesBox.sent .messages { background-color:#919090 !important;}

.vConnectMainWrap .employeeSelectWrap .select2-container--default .select2-selection--multiple .select2-selection__choice .avatar-sm .avatar-group-img-wrap .initial-avtar {
    color: var(--text_neutral_default) !important;
}
.option.load-more {
    color: var(--text_neutral_default) !important;
}
// .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages a {
//     color: var(--text_neutral_ondark) !important;
// }
.vcMobile .employee-multilocation-select.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option[aria-selected=true]:after {
    color: var(--ButtonBackColor) !important;
}
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox-block .messages {
    p {
        a {
            color: var(--text_neutral_default) !important;
        }
    }
}
.img-dark { display: none;}
/*- End: color theme -*/
html[data-theme="dark"] {
    .vConnectMainWrap .rightside .rightside-wrapper .member-list-wrapper #groupNameInput {
        background: #333333;
    }

    .vConnectMainWrap .rightside-wrapper .services-list-wrap.upcoming-appt-wrap .appointment-detail .bottom-icons .serviceIcon {
        .noteRed path {
            fill: #C5392F !important;
        }
    }

    .note {
        border-color: #C5392F !important;
    }
    .img-light { display:none;}
    .img-dark { display: block;}
    .vConnectMainWrap.rightSideOnly .collapsibleChat { border-top: 0px!important;}
}
html[data-theme="light"] {
    .img-dark { display:none;}
 }
 .ai-try-button,  .ai-try-button:hover { color:var(--text_neutral_default);}
 
 