.Paginations {
  display: flex;
  flex-wrap: wrap;
  background: $gray-200;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  .pagerdropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    .form-label{ font-weight: normal; }
    .form-select {
      min-width: 87px;
      padding-top: 0.473rem;
      padding-bottom: 0.473rem; 
    } 
  }
}

.pagination {
  margin: 0;
 gap: 3px;

 .page-link {
  text-align: center;
  border-radius: $pagination-border-radius;
  min-width:$PageLinkwitdh;
  
}

}

.Pagination{
  background-color: #f3f4f2;
  border: none;
  min-height: 50px;
  padding-left: 15px;
  padding-right: 15px;
  color: #4d4e4e;
  font-size: 15px;
  max-width: 910px;
  float: left;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

    & ul.pagination {
      margin: 0;
      display: flex;
      list-style: none;
      padding-left: 0;
      float: left;
      width: auto;
    }
    & .pagerdropdown {
      margin-top: 0;
      display: flex;
      padding-right: 5px;
      padding-left: 5px;
      max-width: 180px;
      float: right;
      align-items: center;

      & label.form-label {
        color: #777;
        font-size: 100%;
        display: flex;
        padding-right: 5px;
        padding-left: 5px;
        white-space: nowrap;
        height: 32px;
        align-items: center;

        & select.form-select {
          height: 32px;
          padding: 0px 15px;
        }
      }
      & select.form-select{
        height: 32px;
        padding: 0px 15px;
      }
    }
}


