

@keyframes stencil-anim {
    0% {
        background-position: 100% 50%;
   }
    100% {
        background-position: 0% 50%;
   }
}

%commanForStencil {
    animation: stencil-anim 1s ease-in-out infinite;
    background-size: 200% 200%;
    background-image: linear-gradient(90deg, #888, #aaa, #888, #aaa) !important;
    background-size: 400% 400% !important;
    opacity: .4 !important;
    pointer-events: none !important;
    color: transparent !important;
    background-color: transparent !important;
}
.vConnectMainWrap {
    .stenciled {
        .dropdown-toggle {display: none;}
        .rightside-wrapper {
            .contact-wrap {
                a {
                    i,
                    svg {
                        width: 0;
                    }
                }
            }
            .businessLocationBlock .iconWrap svg,
            .businessLocationBlock .iconWrap .fa-store {
                opacity: 0;
            }
            
        }
        .bottom-iconsWrap,
        .service-wrap.serviceCard::after,
        .service-wrap.serviceCard::before {
            display: none !important;
        }
        .service-wrap.serviceCard {
            background-color: transparent !important;
            border-color: transparent !important;
        }
        .profile-wrapper .membership-name svg {
            display: none;
        }

        .appointment-detail .date,
        .appointment-detail .service-wrap h3,
        .appointment-detail .service-wrap p,
        .rightside-wrapper .block-status .active-status,
        .appointment-detail .service-wrap .date-wrap,
        .profile-wrapper .name-wrap h3,
        .rightside-wrapper .contact-wrap a span,
        .popup-note-wrap .note p {
            font-size: 0 !important;
        }

        &.rightside {
            .businessLocationBlock .locationList .location {
                color: transparent !important;
            }
        }

        .avatar,
        .name-wrap h3,
        .block-status .active-status,
        // .rightside-wrapper .contact-wrap a,
        .accordion-header .accordion-button,
        .appointment-detail,
        .businessLocationBlock .iconWrap,
        /*.rightside .businessLocationBlock .locationList .location,*/
        .businessLocationBlock .locationList .locationToogle,
        // .contact-wrap a .contact-icon svg path,
        .contact-wrap a .contact-icon i,
        .accordion-header .accordion-button span,
        .accordion-header .accordion-button::after,
        .rightside-wrapper .services-list-wrap.upcoming-appt-wrap .service-wrap,
        .no-data,
        .popup-note-wrap .note,
        .businessLocationBlock .locationList,
        .profile-wrapper .membership-wrap,
        .member-list-wrapper .chatList-person_name,
        .member-list-wrapper .chatList-person_number,
        .textCounArea-sent,
        .progress,
        .textCounArea-limit,
        .textCounArea-contact

        /*.rightside svg path*/
        // .messagesArea-middle .messagesBox-block .name,
        // .messagesArea-middle .messagesBox-block .messages,
        // .messagesArea-middle .messagesBox-block .time
        // .messagesArea-middle .inChatmsg
        {
            /*background: transparent;*/
            margin-bottom: 5px;
            @extend %commanForStencil;
            color: transparent !important;
            border: transparent !important;
            fill: none !important;
        }
        .membership-wrap {
            background-color: transparent;
            width: 130px;
            height: 20px;
            i,
            .membership-name {
                display: none;
            }
        }
        .review-profile-inner-text,
        .review-profile-inner-text.vg-avatar-inital-inner-text,
        .avatar-group-img-wrap {
            display: none;
        }
        .chatList-person_name {
            max-width: 100% !important;
        }
        .rightside-wrapper {            
            .contact-wrap {
                a {
                    align-items: start;
                    .contact-icon {
                        width: 20px;
                    }
                    span {
                        width: 80px;
                        height: 20px;
                    }
                }
            }
        }
        .appointment-detail .service-wrap,
        .appointment-detail .service-icon,
        .accordion-button::after {
            display: none !important;
        }

        .avatar,
        .rightside-wrapper .contact-wrap a {
            img {
                display: none;
            }

            .avatar-group-img-wrap {
                background: none;
            }
            .avatar-group-img-wrap-text {
                display: none;
            }
        }
        .businessLocationBlock .iconWrap,
        .membership-name {
            img {
                opacity: 0;
            }
        }
        .appointment-detail svg,
        .carousel-control-prev,
        .carousel-control-next {
            display: none;
        }
        .contact-wrap a .contact-icon {
            margin-right: 5px;
        }
        .contact-wrap a .contact-icon i {
            margin-bottom: 0;
        }
        .contact-wrap a .contact-icon,
        .contact-wrap a span {
            
            @extend %commanForStencil;
            svg {
                opacity: 0;
            }
        }

        .accordion-header .accordion-button {
            color: transparent !important;
        }

        .appointment-detail > div,
        .note {
            color: transparent !important;
            background-color: transparent !important;
            border-color: transparent !important;

            &::after,
            &::before {
                display: none;
            }
        }

        .rightside-wrapper {
            .services-list-wrap.upcoming-appt-wrap .appointment-detail .bottom-icons,            
            .appointment-detail .service-icon,
            .carousel-control-prev,
            .carousel-control-next {
                display: none;
            }
        }

        .contact-wrap a span {
            // display: none;
        }

        &.services-list-wrap.upcoming-appt-wrap .appointment-detail {
            .bottom-icons::after {
                display: none;
            }
            .bottom-icons { 
                display: none !important;
            }
        }
        .block-status .active-status.status-gray {
            width: 100px;
        }
    }
}

.employeeDetailPophover {
    .stenciled {
        .avatar,
        .rightside-wrapper .profile-wrapper .name-wrap h3,
        .rightside-wrapper .block-status .active-status,
        .rightside-wrapper .services-list-wrap .btn-message,
        .rightside-wrapper .contact-wrap a {
            @extend %commanForStencil;
        }
        .avatar img {display: none;}
        .rightside-wrapper .block-status .active-status {
            height: 15px;
            width: 50px;
        }
        .avatar,
        .review-profile-inner-text,
        .avatar-group-img-wrap,
        .rightside-wrapper .profile-wrapper .name-wrap h3,
        .rightside-wrapper .block-status .active-status,
        .rightside-wrapper .services-list-wrap .btn-message,
        .rightside-wrapper .contact-wrap a {
            font-size: 0 !important;
            background-color: transparent !important;
        }
    }

}

.vConnectMainWrap .stenciled .block-status .active-status {
    font-size: 0 !important;
    width: 60px;
}

.stenciled {
    // #vDriveModal .modal-body .nav-pills .nav-link, 
    // #vDriveModal .modal-body .nav-pills .nav-link.active,
    #vDriveModal .modal-body .card-imgWrap,    
    .drive-file-type i,
    #vDriveModal .modal-body .card-body, 
    #vDriveModal .modal-body .tabTitle {
        margin-bottom: 5px;
        @extend %commanForStencil;
        color: transparent !important;
        border: transparent !important;
        fill: none !important;
    }

    .drive-file-type img {
        width: 0;
    }

    #vDriveModal .modal-body .card-title,
    #vDriveModal .modal-body .card-text,
    #vDriveModal .modal-body .tabTitle h3 {
        font-size: 0;
        line-height: 24px;
    }
}

.stenciledBlockForDrive .card-imgWrap {
    @extend %commanForStencil;
    color: transparent !important;
    border: transparent !important;
    fill: none !important;
}
.stenciledBlockForDrive .card-body {
    @extend %commanForStencil;
    height: 70px;
    border-top: 5px solid #fff;
}

/*- START: Dark theme stenciled -*/
html[data-theme=dark] {
    .vConnectMainWrap {
        .rightside .rightside-wrapper .personal-info.stenciled .block-status .active-status {
            color: transparent !important;
        }
    }
}
/*- END: Dark theme stenciled -*/

.stencilChatarea {
    width: calc(100% - 169px);
    float: right;
    border-left: 1px solid #cdcdcd;
}
.stencilChatListBlock {
    border-right: 1px solid #cdcdcd;
    width: 319px;
    flex: 0 0 319px;
}
.stencilBlock-messagesArea {
    .messagesArea-header {
        background: var(--bkg_neutral_default);
        border-bottom: 1px solid #cdcdcd;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        .stencilAvatar{
            height: 41px;
            width: 41px;
            flex: 0 0 41px;
            border-radius: 50%;
        }
    }
    .messagesArea-middle {
        padding: 15px;
    }
    .messagesBox {
        align-items: start;
        display: flex;
        justify-content: start;
        .stencilAvatar {
            height: 31px;
            width: 31px;
            border-radius: 50%;
            margin-right: 5px;
        }
        .messages {
            margin: 5px 0;
        }
        &.sent {
            justify-content: end;
            .stencilAvatar {
                margin-right: 0;
                margin-left: 5px;
            }
        }
        
    }
}

.stencilBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    &-conversation {
        &_search {
            padding: 33px 16px 12px 15px;
        }
    }
    &-chatList-header {
        padding: 0 8px;
        border-bottom: 1px solid #cdcdcd;
        .tabBlock {
            width: 50%;
        }
    }
    &-chatListBlock {
        height: calc(100vh - 177px);
        overflow-y:hidden;

        &-header {
            height: 25px;
        }
        &-personBlock {
            border-bottom: 1px solid #cdcdcd;
            padding: 15px;
        }
        &-person {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            &_avatar {
                border-radius: 40px;
                height: 40px;
                width: 40px;
                flex: 0 0 40px;
                margin-right: 16px;
                overflow: hidden;
            }
            &_info {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                width: 100%;
                .textLine {
                    height: 15px;                    
                }
            }
        }
    }
    &-messagesArea {
        

        &-middle {
            height: auto !important;
            overflow: hidden;
        }
        .messagesBox-block {
            width: 40%;
            .messages {
                width: 100%;
                max-width: 100% !important;
                height: 40px;
            }
            .name,
            .time {
                width: 80px !important;
            }
        }
        .messagesBox {
            .stencilAvatar.avatar-sm {
                margin-top: 13px;
            }
            &.sent {
                .messagesBox-block {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    flex-direction: column;
                }
            }
        }
        &_left {
            display: flex;
            align-items: center;
            width: 50%;
        }
        &_right {}

        
    }
    &-rightSlide {
        max-width: 100%;

        .proData {
            margin-bottom: 10px;
            text-align: center;
            padding: 15px;
            .stencilAvatar {
                margin: 0 auto;
                margin-bottom: 10px;
            }
        }
        .proDataEmail {
            padding: 15px;
        }
        .sreviceCard {
            padding: 15px;
        }
        
    }
}
.stencilAnimation,
.stencilAvatar,
.stencilTextline {
    animation: stencil-anim 1s ease-in-out infinite;
    background-size: 200% 200%;
    background-image: linear-gradient(90deg, #888, #aaa, #888, #aaa) !important;
    background-size: 400% 400% !important;
    background-color: none;
    opacity: .4 !important;
    pointer-events: none !important;
    width: 100%;
    height: 100%;

    &.border-r3 { border-radius: 3px; }
    &.border-r10 { border-radius: 10px; }

    &.m-0 {
        margin: 0 auto !important;
    }

    &.mb-5 { margin-bottom: 5px !important; }
    &.mb-10 { margin-bottom: 10px !important; }

    &.mr-10 { margin-right: 10px; }
    &.mr-20 { margin-right: 20px; }
    &.mr-30 { margin-right: 30px; }
    &.mr-40 { margin-right: 40px; }
    &.mr-50 { margin-right: 50px; }

    &.ml-10 { margin-left: 10px; }
    &.ml-20 { margin-left: 20px; }
    &.ml-30 { margin-left: 30px; }
    &.ml-40 { margin-left: 40px; }
    &.ml-50 { margin-left: 50px; }

    &.height-10 { height: 10px;}
    &.height-20 { height: 20px;}
    &.height-30 { height: 30px;}
    &.height-40 { height: 40px;}
    &.height-50 { height: 50px;}
    &.height-60 { height: 60px;}
    &.height-70 { height: 70px;}
    &.height-80 { height: 80px;}
    &.height-90 { height: 90px;}
    &.height-100 { height: 100px;}

    &.width-10 { width: 10px;}
    &.width-20 { width: 20px;}
    &.width-30 { width: 30px;}
    &.width-40 { width: 40px;}
    &.width-50 { width: 50px;}
    &.width-60 { width: 60px;}
    &.width-70 { width: 70px;}
    &.width-80 { width: 80px;}
    &.width-90 { width: 90px;}
    &.width-100 { width: 100px;}
}

// .messagesArea-header.viewchange {
//     .messagesArea-header_avGroup .avatar {
//         animation: stencil-anim 1s ease-in-out infinite;
//         background-size: 200% 200%;
//         background-image: linear-gradient(90deg, #888, #aaa, #888, #aaa) !important;
//         background-size: 400% 400% !important;
//         opacity: .4 !important;
//         pointer-events: none !important;
//         width: 40px;
//         height: 40px;
//         .avatar-group-img-wrap {
//             display: none;
//         }
//     }
//     .messagesArea-header_name,
//     .messagesArea-header_btnArea {
//         animation: stencil-anim 1s ease-in-out infinite;
//         background-size: 200% 200%;
//         background-image: linear-gradient(90deg, #888, #aaa, #888, #aaa) !important;
//         background-size: 400% 400% !important;
//         opacity: .4 !important;
//         pointer-events: none !important;
//         color: transparent !important;
//     }
// }



/*-HariOm-*/
.user-stensil-block {
    width: 100%;
    max-width: 100%;
    padding: 0px;
}

    .user-stensil-block .class-row-stensil {
        padding: 16px;
        display: flex;
        align-items:flex-start;
        border-bottom: 1px solid rgba(0, 0, 0, .15);
        word-wrap: break-word;
    }

    .user-stensil-block .clstleft-part {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

        .user-stensil-block .clstleft-part .time-strnsil {
            width: 77px;
            margin-right: 19px;
            margin-top: 4px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
        }

            .user-stensil-block .clstleft-part .time-strnsil .cltime-div {
                width: 76px;
                height: 13px;
                overflow: hidden;
                position: relative;
                border-radius: 20px;
                background: #ececec;
                margin-bottom: 9px;
            }

            .user-stensil-block .clstleft-part .time-strnsil .clmin-div {
                width: 76px;
                height: 22px;
                overflow: hidden;
                position: relative;
                border-radius: 20px;
                background: #ececec
            }

        .user-stensil-block .clstleft-part .class-bus-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width:100%;
        }

        .user-stensil-block .clstleft-part .class-bus-inn {
            display: flex;
            justify-content: center;
            align-items: center;
            width:90%;
        }

        .user-stensil-block .clstleft-part .classbusi-img {
            width: 41px;
            height: 41px;
            background: #ececec;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            flex:none;
        }

        .user-stensil-block .clstleft-part .classbusi-detail {
            padding-left: 12px;
            display: flex;
            align-items: self-start;
            flex-direction: column;
            width:100%;
        }

        .user-stensil-block .clstleft-part .clbusiness-name {
            width: 100%;
            height: 15px;
            border-radius: 20px;
            background: #ececec;
            overflow: hidden;
            margin-bottom: 10px;
            position: relative
        }

        .user-stensil-block .clstleft-part .clbusiness-type {
            display: flex;
            align-items: center;
            width:100%;
        }

            .user-stensil-block .clstleft-part .clbusiness-type .halfDiv-type {
                
                height: 13px;
                border-radius: 20px;
                background: #ececec;
                overflow: hidden;
                position: relative;
                margin-right: 10px;
                width:65%;
            }

    .user-stensil-block .clstright-part {
        display: flex;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

        .user-stensil-block .clstright-part .clstprice {
            display: inline-flex;
            justify-content: flex-end;
            margin-right: 0px;
            align-items: center;
            max-width: 50px;
            width:100%;
            min-width:50px;
            height:15px;
            border-radius: 9px;
            overflow: hidden;
            background: #ececec;
            position: relative
        }

        .user-stensil-block .clstright-part .clstsignup {
            float: right;
            width: 115px;
            overflow: hidden;
            position: relative;
            background: #ececec;
            border-radius: 3px;
            min-height: 40px
        }


.common-livestrnsil-ani {
    width: 100%;
    height: 100%;
    background: #ececec;
    background: -moz-linear-gradient(left,#ececec 0%,#f4f4f4 50%,#ececec 100%);
    background: -webkit-linear-gradient(left,#ececec 0%,#f4f4f4 50%,#ececec 100%);
    background: linear-gradient(to right,#ececec 0%,#f4f4f4 50%,#ececec 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ececec',endColorstr='#ececec',GradientType=1);
    -webkit-animation: liveani 1s infinite;
    -moz-animation: liveani 1s infinite;
    -ms-animation: liveani 1s infinite;
    animation: liveani 1s infinite;
    position: absolute;
    top: 0;
    left: 0;
}

.stensil-msg-header {
    background: #fff;
    border-bottom: 1px solid #cdcdcd;
    height: 44px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    
}
.stensil-msg-header-wrap {  flex-grow:1;
}
.stensil-msg-header-text {
    overflow: hidden;
    position: relative;
    background: #ececec;
    border-radius: 3px;
    height: 20px;
    width: 100%;
    max-width: 60%;
    margin:0 auto;
}

.stensil-box {
    overflow: hidden;
    position: relative;
    background: #ececec;
    border-radius: 3px;
    height:15px;
}

.stensil-navigation {
    display: flex;
    justify-content: space-around;
}
.stensil-navigation-link { padding:16px; width:100%; height:50px; position:relative; overflow:hidden; border-bottom:solid 2px #cdcdcd;
}
.stensil-search {
    padding: 16px;
    gap:16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.stensil-search-wrap {width: 85%;}
.stensil-search-btnWrap {width: 15%;}
.sten-height40 { height:40px;}

.stencilBlock-messagesArea .messagesArea-middle { overflow: hidden!important;}
.stencilBlock-rightSlide, .user-stensil-block { height:100vh; overflow: hidden;}


@-moz-keyframes liveani {
    0% {
        left: -100%
    }

    100% {
        left: 100%
    }
}

@-webkit-keyframes liveani {
    0% {
        left: -100%
    }

    100% {
        left: 100%
    }
}

@keyframes liveani {
    0% {
        left: -100%
    }

    100% {
        left: 100%
    }
}
/*--*/