.nav-tabs {
  gap: 16px;
  border-color: $border-color;
  .nav-link {
    color: $gray-600;            
    padding: 5px 0;
    border: none;
    cursor: pointer;        
    svg {
      margin-right: 5px;
      font-size: 85%;
    }
    &.active {
      background: none;
      color: $body-color;
      padding-right: 0;
      padding-left: 0;
      border-bottom: 2px solid $body-color;
      border-radius: 0;
    }
    &:hover {
      border-bottom-width: 2px;
    }
  }
}
.vertical-tab {
  .nav { 
    .nav-link {
      color: $gray-600;            
      cursor: pointer;
      padding: 12px 0;
      border-bottom: 1px solid $border-color;
      border-radius: 0;
      &.active {
        background: none;
        color: $body-color;
        padding-right: 0;
        padding-left: 0;
        border-color: $body-color;
        border-radius: 0;
        border-width: 2px;
      }
    }
  }
}
