

html {
  font-size: 100%;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behaviour: smooth;
}

/* browser scrollbar style*/
/*
body {
  font-family: var(--vg-baseFont);
  font-size: var(--vg-basSize);
  line-height: var(--vg-line-height);
  background-color: #f8f8f8;
  min-width: 320px;
}
*/

/* browser scrollbar style */
* {
 /* scrollbar-color: rgba(2, 2, 2, 0.5) #f6f4f3;
  scrollbar-width: thin;*/
  /* scroll-behavior: smooth; */
}

::-webkit-scrollbar {
  width: 7px !important; 
  background-color: #d9d9d9;border-radius: 10px;
}

::-webkit-scrollbar:horizontal{
  height: 6px;
  background-color: #d9d9d9;border-radius: 10px;
}

@media (max-width: 462px){
  ::-webkit-scrollbar {
    width: 7px!important; 
  }
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #7d7e7e;border-radius: 10px;
  position: relative;
  z-index: 999;
}


body:not(.ipadandroid) ::-webkit-scrollbar, 
body:not(.ipadandroid) ::-webkit-scrollbar {width: 7px;background-color: #d9d9d9;border-radius: 10px;}
body:not(.ipadandroid) ::-webkit-scrollbar-thumb, 
body:not(.ipadandroid) ::-webkit-scrollbar-thumb {background-color: #7d7e7e;border-radius: 10px;}
body:not(.ipadandroid) ::-webkit-scrollbar-track, 
body:not(.ipadandroid) ::-webkit-scrollbar-track { border-radius: 10px; }

/*******/ 