table.table {
  font-size: $table-font-size;
  border-radius: $table-border-radius; margin: 0;
  overflow: visible;
  > :not(:first-child) {
    border-width: $table-border-width;
  }
  > thead, .thead {
    color: $table-headtext-color;
    background: $table-head-bg;
    border: $table-head-border solid $table-border-color;
    th, .th {
      padding: $table-cell-head-padding-y $table-cell-head-padding-x;
      line-height: normal;
      vertical-align: middle;
      font-weight: 500;
      &.highlighted{background-color: $gray-300!important;}
    }
  }
  > tbody, .tbody {
    font-weight: $table-font-weight;
    td{
      &.highlighted{background-color: $gray-100!important;}
    }
  }
  > tfoot, .tfoot{
    background: $table-head-bg;
    td{font-weight: 500;
      &.highlighted{background-color: $gray-300!important;}
    }
  }
  &-borderless {
    tr {
      border-bottom: 1px solid $table-border-color;
    }
  }
  > tfoot, .tfoot {
    font-weight: $table-foot-weight;
  }
}

//    .Tablefixedheade{
//       // thead{
//       //    th{font-weight: normal; background: #666666; color: #fff; padding: 10px 10px;}
//       // }
//       // > :not(:first-child) { border-width: 1px;}
//       // tbody{ }
//     }


.actiontd{width: 120px;}

.table-fixed-head{
  tfoot{display: none;}
}