@include media-breakpoint-up(sm) {
  .list-group-item {
    padding: $list-group-item-padding-y $list-group-item-padding-x;
    &:hover {
      border-color: $list-group-hover-border-color;
      z-index: 2;
    }
    & + & {
      &:hover {
        margin-top: -$list-group-border-width;
        border-top-width: $list-group-border-width;
      }
    }
  } 
  .list-group-item-action {
    &:hover {
      border-color: inherit;
    }
  }
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .list-group-horizontal#{$infix} {
      flex-direction: row;
      > .list-group-item {
        padding: $list-group-item-padding-Hy $list-group-item-padding-Hx;
        + .list-group-item {
          &:hover {
            margin-top: 0;
            margin-left: -$list-group-border-width;
            border-left-width: $list-group-border-width;
          }
        }
      }
    }
  }
}

.list-group-switch {
  &.bordertopshow {
    border-top: 1px solid $border-color;
  }

  .list-group-item {
    border: none;
    border-bottom: 1px solid $border-color;
    padding: 1.5rem 0 0.5rem;
    margin: 0;

    + .list-group-item {
      margin: 0;
    }
    h4 {
      padding-bottom: 4px;
      margin-bottom: 0;
    }
    p {
      color: $form-text-color;
    }
  }
  &.bordernone {
    .list-group-item {
      border-width: 0;
    }
  }

  &.borderbottomhide {
    .list-group-item {
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
