
.dropdown-menu{  box-shadow:$dropdown-box-shadow ; font-size: $table-font-size; padding: 0.5rem 0;}  
.dropdown-toggle {
    &:after{border-radius: $border-radius;} 
    &.btn{ display: flex; align-items: center;
    // &:focus{ color: $white;}
    &-default,&-light{
      &:focus{ color: $dark;}
    }
    }
  }

  .dropdown-item {color: $gray-900;}


.export-icon.download.missedcheckout-export-report-icon .dropdown-toggle:after {
    content: none;
}
.export-icon.download.v_hide_mbl.missedcheckout-export-report-icon a {
  color: #333 !important;
  text-decoration: none;
}
.export-icon.download.v_hide_mbl.missedcheckout-export-report-icon {
  justify-content: flex-end;
  display: flex;
}
.export-icon.missedcheckout-export-report-icon .dropdown-menu a {
  font-size: 15px;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
}
 
@media (max-width: 767px){
  .export-icon.download.v_hide_mbl.missedcheckout-export-report-icon{
    display: none;
}

}