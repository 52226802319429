.modal-header {
  padding-bottom: $modal-padding-b;
  .btn-close {
    font-size: $modal-btn-close-size; 
    margin: ($modal-header-padding-y * -.8) ($modal-header-padding-x * -.58) 0 auto;
    background: transparent escape-svg($modal-btn-close-bg) center / $btn-close-width auto no-repeat;   
    
  }
}

.modal-title {
  font-size: $modal-header-title-size;
}

.modal-body {
  padding: $modal-body-padding-y $modal-body-padding-x;
  p {
    margin-bottom:$modal-body-padding-y ;
  }
}
.modal-content {
  box-shadow: $modal-content-box-shadow;
}
.modal-footer {
  background-color: $modal-footer-bg-color;
  padding: $modal-footer-y $modal-footer-x;
  gap: $modal-footer-gap;
}
.modal-avtar {
  margin-left: $modal-avtar-margin-x;
  margin-right: $modal-avtar-margin-x;
  border-radius: $modal-avtar-radius;
  img {
    max-width: $modal-avtar-size;
    border: 1px solid $border-color;
    object-fit: $Image-object-cover;
    border-radius: $modal-avtar-radius;
  }
}
