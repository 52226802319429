@import "bootstrap/variables";
@import "~bootstrap/scss/bootstrap";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "bootstrap/buttons";
@import "bootstrap/modal";
@import "bootstrap/card";
@import "bootstrap/forms/labels";
@import "bootstrap/forms/form-check";
@import "bootstrap/forms/vg-select";
@import "bootstrap/pagination";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/tabs";
@import "bootstrap/list-group";
@import "bootstrap/tables";
// @import "~fontawesome";
// @import "bootstrap/dtepickernew";
@import "bootstrap/daterangepicker";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css";
$link-color : null !default;

a {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-color;
    text-decoration: $link-hover-decoration;
  }
}



// layout style
.mainArea  { position: relative; background: $white; overflow: hidden;
  padding-left: 20px; padding-right: 20px;
  @include media-breakpoint-down(lg1024) {
    padding-left: 59px;
  }
  @include media-breakpoint-down(lg) {
    padding-right: 15px;
  }
}

#sideBar {
  height: calc(100vh - 51px);
  overflow-y: auto;
  scrollbar-color: var(--vg-scrollbar) var(--vg-bgLighGray);
  scrollbar-color: rgba(2, 2, 2, 0.5) var(--vg-bgLighGray);
  background: #fff;
  padding-bottom: 60px;
}

.content {
  background-color: var(--white);
  max-width: 1050px;
  justify-content: start;
  flex-flow: column;
  padding: 15px;
}
@include media-breakpoint-down(md) {
  .content {
    max-width: auto;
    margin: 0;
  }
}
@include media-breakpoint-up(lg) {
  .midcontent {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: $midcontent;
  }
}


// global style

select {
  @extend .form-select;
}

.form-control {
  resize: none;
}
.text-muted {
  font-size: 15px;
  line-height: 20px;
}
.is-invalid {
  background-color: $input-disabled-bg;
  &::placeholder {
    color: $body-color;
  }
  &:focus {
    box-shadow: none;
  }
}
.invalid-feedback {
  text-align: right;
  font-size: 0.75rem;
}

.input-group {
  &.icon {
    position: relative;
    .form-control {
      padding-left: 30px;
    }
    .input-group-text {
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      background: none;
      margin: 0 1px;
      color: $form-text-color;
    }
    &.right {
      .form-control {
        padding-left: $input-padding-x;
      }
      .input-group-text {
        left: auto;
        right: 0;
      }
    }
  }
  &.qty {
    .input-group-text {
      cursor: pointer;
      padding: 0.44rem 0.75rem;
      background: $gray-400;
      &:hover {
        background: $gray-500;
      }
    }
  }
  .dropdown-toggle {
    background: none;
    border: 1px solid $border-color;
    border-radius: 3px 0 0 3px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0 6px 0 8px;
    &:after {
      border-top-color: $body-color;
      margin-left: 7px;
      border-width: 6px;
      border-bottom: 0;
      border-radius: 5px;
    }
    &:hover {
      background-color: $gray-400;
    }
  }
}

.rgGroupCol .rgCollapse {
  width: 18px;
  height: 18px;
  display: block;
  border: 0px;
  background: transparent url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/symbol-white-ico18x18.png') no-repeat 0px -18px;
}

.rgGroupCol .rgExpand {
  width: 18px;
  height: 18px;
  display: block;
  border: 0px;
  background: transparent url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/symbol-white-ico18x18.png') no-repeat 0px 0px;
}

.show {
  display: block!important;
}
.hide {
  display: none!important;
}

.report-content {
  &.fullreportscroll {
    margin: 105px -19px 60px;
    overflow: auto;
    padding-left: 19px;
    padding-right: 19px;
    @include media-breakpoint-down(lg) {
      margin: 105px -15px 60px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

body{
  &.ipadandroid
  //, &.macipadandroid
{
    #sideBar {height: calc(100vh - 135px);}
  }
}