//
// button styles
//

.btn {
  display: flex;
  gap: 0.5em;
  justify-content: center;
  align-items: center;
  font-weight: 400;

  @include media-breakpoint-down(md) {
    font-size: $mobile-font-size-base;
    &:not(.btn-sm, .btn-lg, .btn-icon) {
      padding-top: 0.797rem;
      padding-bottom: 0.797rem;
    }
  }
  & > svg {
    font-size: 90%;
  }
  &-primary {   
    &:hover {
      background: $FlamingoDark;      
    }
    &:focus,
    &:active,
    &.active {
      background: $FlamingoDarker;
      border-color: $FlamingoDarker;
    }
  }
  &-secondary {
    $border-color: false;
    color: $body-color;
    border-color: $border-color;
    background: $white;
    &:hover {
      background: $gray-100;
      color: $body-color;
      border-color: $gray-400;
    }
    &:focus, 
    &:active,
    &.active {
      background: $gray-300;
      border-color: $gray-400;
      color: $body-color;
    }
    &:disabled,
    &.disabled {
      color: $body-color;
      border-color: $border-color;
      background: $white;
    }
  }
  &-theme {
    color: $white;
    &:hover {
      background: $FlamingoDark;
      border-color: $FlamingoDark;
    }
    &:focus,
    &:active,
    &.active {
      background: $FlamingoDarker;
      border-color: $FlamingoDarker;
    }
    &:disabled,
    &.disabled {
      opacity: 0.5;
    }
  }
  &-dark {
    color: $white;
    background: $body-color;
    &:hover {
      background: $black;
      border-color: $black;
    }
    &:focus,
    &:active,
    &.active {
      background: $black;
      border-color: $black;
    }
  }
  &-warning {
    color: $white;
    &:hover {
      color: $white;
    }
    &:focus,
    &:active,
    &.active {
      color: $white;
    }
  }
  &-delete {
    border-color: transparent;
    color: $red;
    &:hover {
      color: $red;
      border-color: $red;
    }
    &:focus,
    &:active,
    &.active {
      color: $red;
      border-color: $red;
      background-color: $gray-100;
    }
  }

  &-filter {
    border-color: $border-color;
    &:hover {
      @extend .btn-secondary;
      background: $white;
      box-shadow: 0 2px 10px rgba($black, 0.1);
    }
    &:focus,
    &:active, 
    &.active { 
      box-shadow: 0 0px 4px rgba($black, 0.16);
      background-color: $gray-100;
    }
    &:disabled,
    &.disabled {
      opacity: 0.5;
    }
  }

  &-icon {
    padding: 0.688rem;
    border-color: $border-color;
    & > svg {
      font-size: 100%;
    }
  }

  &-round {
    border-radius: $border-radius-pill;
  }

  &-seeall {
    color: $blue;
    &:hover {
      background: rgba($blue, 0.08);
      color: $blueDark;
    }
    &:focus,
    &:active,
    &.active {
      background: rgba($blue, 0.08);
      color: $blueDarker;
    }
    &:disabled,
    &.disabled {
      opacity: 0.5;
    }
  }
  &-dot {
    background: none;
    border-color: transparent;
    color: $body-color;
    border-radius: 5px;
    &:hover {
      background: $gray-300;
      border-color: transparent;
    }
    &:focus,
    &:active,
    &.active {
      background: $gray-400;
      border-color: transparent;
    }
  }
  &-tag {
    background: none;
    border-color: transparent;
    color: $body-color;
    padding: 0;

    i {
      padding: 0 0.25rem;
      border-radius: $border-radius;
      line-height: 20px;
    }
    &:hover i {
      background: $gray-400;
      border-color: transparent;
    }
    &:focus i,
    &:active i,
    &.active i {
      background: $gray-500;
      border-color: transparent;
    }
  }

  &-outline {
    &-secondary {
      border-color: $border-color;
      &:hover {
        background: $gray-100;
        color: $body-color;
        border-color: $gray-400;
      }
      &:focus,
      &:active,
      &.active {
        background: $gray-300;
        border-color: $gray-300;
        color: $body-color;
      }
    }
    &-danger {
      &:hover {
        background: $gray-100;
        color: $red;
      }
      &:focus,
      &:active,
      &.active {
        background: $gray-300;
        color: $red;
        border-color: $red;
      }
      &:disabled,
      &.disabled {
        opacity: 0.5;
      }
    }
  }
  &-inline {
    color: $blue;
  }
  &-outline-inline {
  }
}



.show > .btn-secondary.dropdown-toggle {
  @extend .btn-secondary;   
 
}
 